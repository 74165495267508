import classnames from 'classnames';
import React from 'react';

export interface INavigationLink {
  name: string;
  link: string;
  target: string;
}

interface INavigationProps {
  links: INavigationLink[];
}

const Navigation = ({ links }: INavigationProps) => {
  return (
    <div className="sf_navigation">
      <div style={{ display: 'block' }}>
        <ul id="Nav1" className="nav">
          {links.map((navLink, idx) => (
            <li key={navLink.name} className={classnames({'sf_first_nav_item': idx === 0, 'sf_last_nav_item': idx === links.length - 1})}>
              <a href={navLink.link} target={navLink.target}>
                {navLink.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navigation;
