import React from 'react';
import { Helmet } from 'react-helmet';

import Navigation from './Navigation';

import '../assets/stylesheets/layoutcontainer.css';
import '../assets/stylesheets/theme.css';
import '../assets/stylesheets/color_2.css';
import '../assets/stylesheets/custom.css';
import '../assets/stylesheets/global.css';
import { graphql, StaticQuery } from 'gatsby';

interface LayoutProps {
  children: React.ReactNode;
  pageTitle?: string;
  bodyId?: string;
}

export default function Layout({ children, pageTitle, bodyId }: LayoutProps) {
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
              siteUrl
              navLinks {
                name
                link
                target
              }
            }
          }
        }
      `}
      render={(data) => {
        const title = `${data.site.siteMetadata.title} ${pageTitle ? ` - ${pageTitle}` : ``}`;
        return (
        <React.Fragment>
          <Helmet
            bodyAttributes={{
              id: bodyId,
              class: 'main-body',
            }}>
            <title>{title}</title>
            <meta name="robots" content="noindex, nofollow" />
            <meta property="og:title" content={title} />
            <meta name="title" content={title} />
            {/* TODO: Figure out the opengraph tags */}
            <meta property="og:type" content="article" />
            <meta property="og:url" content="http://www.swlcc.com/" />
            <meta property="og:image" content="http://swlcc.com/images/91fa0e22075deefe49ecd851377a00c0.jpg" />
            <meta property="og:image" content="http://swlcc.com/images/48464223.jpg" />
            <meta property="og:site_name" content="Saltwater Lure Collectors Club" />
            <meta property="og:description" content="Check out http://swlcc.com!" />
            <meta http-equiv="Content-Type" content="text/html;charset=utf-8" />
            <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
            <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
            <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
            <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
            <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
            <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
            <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
            <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
            <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/manifest.json" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
            <meta name="theme-color" content="#ffffff"></meta>
          </Helmet>

          <div className="sf_outer_wrapper">
            <div className="sf_extra1">
              <span></span>
            </div>

            <div className="sf_wrapper">
              <div
                style={{
                  display: 'block',
                  overflow: 'visible',
                  margin: 0,
                  padding: 0,
                }}>
                <div
                  className="sf_undocked"
                  style={{
                    display: 'block',
                    height: 0,
                    position: 'absolute',
                    margin: 0,
                    padding: 0,
                    overflow: 'visible',
                    width: 0,
                    top: 0,
                    zIndex: 999998,
                    left: 'auto',
                  }}></div>
              </div>

              <div className="sf_navigation_top"></div>

              <div className="sf_extra10">
                <span></span>
              </div>

              <div className="sf_header_wrapper">
                <div className="sf_extra2">
                  <span></span>
                </div>

                <div className="sf_main_header">
                  <div style={{ display: 'block' }}></div>
                </div>

                <div className="sf_extra3">
                  <span></span>
                </div>

                <div className="sf_sub_header">
                  <div style={{ display: 'block' }}></div>
                </div>
              </div>

              <div className="sf_extra4">
                <span></span>
              </div>

              <Navigation links={data.site.siteMetadata.navLinks} />

              <div className="sf_extra5">
                <span></span>
              </div>

              <div className="sf_pagetitle">
                <div style={{ display: 'block' }} dangerouslySetInnerHTML={{ __html: pageTitle }}></div>
              </div>

              <div className="sf_extra6">
                <span></span>
              </div>

              <div className="sf_subnavigation"></div>
              <div className="sf_extra11">
                <span></span>
              </div>

              <div className="sf_content">{children}</div>

              <div className="sf_extra12">
                <span></span>
              </div>

              <div className="sf_subnavigation2"></div>

              <div className="sf_extra7">
                <span></span>
              </div>

              <div className="sf_footer">
                <div style={{ display: 'block' }}>Content copyright 2009-{new Date().getFullYear()} SWLCC All rights reserved.</div>
              </div>

              <div className="sf_extra8">
                <span></span>
              </div>
            </div>

            <div className="sf_extra9">
              <span></span>
            </div>
          </div>
        </React.Fragment>
      )}}
    />
  );
}
