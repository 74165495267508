import { getCookie, setCookie } from './cookie';

const COOKIE_NAME = 'swlccAuth';

export const authenticate = (username, password): boolean => {
  const un = 'QXJub2xk';
  const pw = 'TGFpbmU=';

  if (btoa(username) === un && btoa(password) === pw) {
    setCookie(COOKIE_NAME, 'true', 1);
    return true;
  } else {
    return false;
  }
};

export const isAuthenticated = (): boolean => {
  if (getCookie(COOKIE_NAME)) {
    return true;
  }

  return false;
};
